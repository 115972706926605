package io.github.shaksternano.albert21.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.functions.invert
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import io.github.shaksternano.albert21.components.AlbertCursor
import io.github.shaksternano.albert21.components.ConfettiImage
import io.github.shaksternano.albert21.components.Fluttershy
import io.github.shaksternano.albert21.components.widgets.ColorModeButton
import io.github.shaksternano.albert21.components.widgets.ShukaiButton
import org.jetbrains.compose.web.css.*

private const val GREETING_ANIMATION_DURATION = 5
private const val GREETING_ANIMATION_ROTATIONS = 3

private const val MESSAGE_BODY_ANIMATION_DURATION = 3
private const val MESSAGE_BODY_ANIMATION_AMPLITUDE = 30
private const val MESSAGE_BODY_ANIMATION_FREQUENCY = 5

private const val CLOSING_ANIMATION_DURATION = 2
private const val CLOSING_ANIMATION_FREQUENCY = 2

@Page
@Composable
fun HomePage() {
    Box(Modifier.fillMaxSize()) {
        var showShukai by remember { mutableStateOf(false) }
        val colorMode = ColorMode.current
        val invertLevel = if (colorMode.isLight) 1 else 0
        if (showShukai) {
            Image(
                "/shukai.png",
                "Shukai",
                Modifier
                    .fillMaxHeight()
                    .align(Alignment.Center)
                    .filter(invert(invertLevel)),
            )
        }
        Row(
            Modifier.fillMaxSize().padding(5.cssRem),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            ConfettiImage()
            Spacer()
            ConfettiImage(flip = true)
        }
        PageContent()
        Fluttershy()
        Row(
            Modifier.fillMaxWidth().padding(1.cssRem),
            horizontalArrangement = Arrangement.End,
        ) {
            ShukaiButton {
                showShukai = !showShukai
            }
            ColorModeButton()
        }
        AlbertCursor()
    }
}

@Composable
fun PageContent() {
    Column(
        Modifier.fillMaxSize().gap(2.cssRem),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        SpanText(
            "Dear Albert,",
            Modifier
                .fontSize(3.cssRem)
                .position(Position.Relative)
                .animation(
                    GreetingKeyframes.toAnimation(
                        duration = GREETING_ANIMATION_DURATION.s,
                    )
                ),
        )

        val messageBodyAnimationDuration = GREETING_ANIMATION_DURATION.s + MESSAGE_BODY_ANIMATION_DURATION.s
        SpanText(
            "Happy 21st Birthday!",
            Modifier
                .fontSize(2.cssRem)
                .position(Position.Relative)
                .animation(
                    MessageBodyKeyframes.toAnimation(
                        duration = messageBodyAnimationDuration,
                    )
                ),
        )

        val closingAnimationDuration = messageBodyAnimationDuration + CLOSING_ANIMATION_DURATION.s
        SpanText(
            "From Shukai",
            Modifier
                .fontSize(2.cssRem)
                .position(Position.Relative)
                .animation(
                    ClosingKeyframes.toAnimation(
                        duration = closingAnimationDuration,
                    )
                ),
        )
    }
}

val GreetingKeyframes = Keyframes {
    from { Modifier.scale(0.percent).rotate(0.deg) }
    to { Modifier.scale(100.percent).rotate((360 * GREETING_ANIMATION_ROTATIONS).deg) }
}

val MessageBodyKeyframes = Keyframes {
    val waitingPercentage = 100 * GREETING_ANIMATION_DURATION /
        (GREETING_ANIMATION_DURATION + MESSAGE_BODY_ANIMATION_DURATION)
    each(0.percent, waitingPercentage.percent) { Modifier.left(100.percent) }
    to { Modifier.left(0.percent) }
    var y = 0
    for (i in 100 downTo 0 step MESSAGE_BODY_ANIMATION_FREQUENCY) {
        each(i.percent) {
            Modifier.bottom(y.percent)
        }
        y += if (y == 0) MESSAGE_BODY_ANIMATION_AMPLITUDE
        else -MESSAGE_BODY_ANIMATION_AMPLITUDE
    }
}

val ClosingKeyframes = Keyframes {
    val waitingPercentage = 100 * (GREETING_ANIMATION_DURATION + MESSAGE_BODY_ANIMATION_DURATION) /
        (GREETING_ANIMATION_DURATION + MESSAGE_BODY_ANIMATION_DURATION + CLOSING_ANIMATION_DURATION)
    each(0.percent, waitingPercentage.percent) { Modifier.top(100.percent) }
    var y = MESSAGE_BODY_ANIMATION_AMPLITUDE
    for (i in (waitingPercentage + 1)..100 step CLOSING_ANIMATION_FREQUENCY) {
        each(i.percent) {
            Modifier.top(y.percent)
        }
        y += if (y == 0) MESSAGE_BODY_ANIMATION_AMPLITUDE
        else -MESSAGE_BODY_ANIMATION_AMPLITUDE
    }
}
