package io.github.shaksternano.albert21.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.functions.invert
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.filter
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.cssRem

@Composable
fun ShukaiButton(onClick: () -> Unit) {
    val colorMode = ColorMode.current
    val invertLevel = if (colorMode.isLight) 1 else 0
    IconButton(onClick) {
        Image(
            "/shukool.png",
            "Shukai",
            Modifier.maxHeight(2.cssRem).filter(invert(invertLevel)),
        )
    }
    Tooltip(ElementTarget.PreviousSibling, "Shukai", placement = PopupPlacement.BottomRight)
}
